$fy-primary-blue: #42bfdd;
$fy-secondary-blue: #08c7d7;
$fy-tertiary-blue: #7ae6f0;
$fy-main: linear-gradient(to left, $fy-secondary-blue, $fy-primary-blue);
$fy-main-vertical: linear-gradient(to bottom, $fy-secondary-blue, $fy-primary-blue);
$fy-gray: #f9f9f9;
$fy-foncy-gray: #e6e1e1;
$fy-dark-gray: #8b8ea0;
$fy-charcoal-gray: #333333;
$fy-pink: #ec008c;
$fy-dark: #000000;
$fy-white: #ffffff;
$fy-light-dark: #5a5a5a;
$fy-red: #ec2626;
$fy-electric-red: #ff3333;
$fy-blue-gray: #e3e5ee;
$fy-light-gray: #eceef0;
$fy-pale-blue: #f6fafd;
$fy-cornflower-blue: #6495ed;
$fy-light-silver: #eee;
$fy-yellow-orange: #fbbb00;
$fy-eddish-pink: #f03759;
$fy-cyan: #00c5bd;
$fy-medium-green: #00b300;
$fy-deep-blue: #2980b9;
$fy-silver: #f2f2f2;
$fy-gainsboro: #ddd;
$fy-dodger-blue: #3498db;
$fy-dim-gray: #555;
$fy-white-smoke: #f5f5f5;
$fy-pale-silver: #e0e0e0;
$fy-teal: #4CAF50;