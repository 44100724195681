@import '~bootstrap/scss/bootstrap.scss';

@import './styles/global/fonts';
@import './styles/global/colors';

/* You can add global styles to this file, and also import other style files */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Helvetica Neue', sans-serif;
}

*,
::after,
::before {
  box-sizing: unset;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  $base-font-size: 1rem;
}

.center {
  text-align: center !important;
}

.lefty {
  text-align: left;
}

.fully {
  width: 100%;
  height: 100%;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-border {
  border: 0 !important;
}

/* Scroll-bar style */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #b9b8b8;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #b9b8b8;
}

/* End of Scroll-bar style */

// Spinner in center
mat-spinner {
  margin: 0 auto !important;
}

.snotifyToast {
  font-weight: bold;

  &__body {
    font-size: 0.75rem;
    font-weight: normal;
  }
}

// Mat tooltip
.mat-tooltip {
  font-size: 0.9rem !important;
}

// special dialog class
.fy-dialog {
  .mat-dialog-container {
    border-radius: 15px;
    box-shadow: 7px 0 15px -10px $fy-foncy-gray;
  }
}

.mat-menu-panel {
  width: 250px !important;
  margin-top: 5px !important;
  border-radius: 15px !important;
}

.dialog-close-btn {
  color: $fy-dark-gray;
}

.mobile_btn {
  display: none;
  float: left;
}

.hideSideBar {
  margin-left: -271px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: 1041;
  //padding: 15px 2px 10px 0 !important;
  //width: 60px !important;
}

.showSideBar {
  margin-left: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.mobile_btn {
  color: #48c4e9;
  cursor: pointer;
  display: block;
  font-size: 24px;
  height: 60px;
  left: 0;
  line-height: 60px;
  padding: 0 15px;
  position: absolute;
  text-align: center;
  top: 10px;
  z-index: 10;
}

// bootstrap overwrite
a {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.table > :not(caption) > * > * {
  padding: 0;
}

.modal-dialog {
  pointer-events: auto !important;
}

.text-secondary {
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: normal;
}

.mat-menu-panel {
  overflow: hidden !important;
}

.linear-color {
  background: -webkit-gradient(linear, left top, left bottom, from(#007fff), to(#00d9f9));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: #fd040400;
}

@mixin button-style($padding, $max-width, $min-width, $background) {
  padding: $padding;
  max-width: $max-width;
  min-width: $min-width;
  background: $background;
  border-radius: 20px;
}

.content-buy-btn {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__signin {
    @include button-style(1rem, 25rem, 20rem, none);
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  }

  &__mobile {
    @include button-style(
      1rem,
      30rem,
      25rem,
      radial-gradient(75.07% 14.17% at 82.53% 24.57%, #4a92c2 0%, #4a9ac2 100%)
    );
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;

    &_echec {
      background: radial-gradient(75.07% 14.17% at 82.53% 24.57%, #ababab 0%, #b8b5b5 100%) !important;
    }
  }

  &__mobile_echec {
    @include button-style(
      1rem,
      25rem,
      20rem,
      radial-gradient(75.07% 14.17% at 82.53% 24.57%, #abababb9 0%, #b8b5b5 100%) !important
    );
  }

  &__mobile_success {
    @include button-style(
      1rem,
      25rem,
      20rem,
      radial-gradient(75.07% 14.17% at 82.53% 24.57%, rgba(9, 201, 131, 0.849) 0%, rgba(9, 201, 131, 0.788) 100%)
        !important
    );
    @include button-style(
      1rem,
      25rem,
      20rem,
      radial-gradient(75.07% 14.17% at 82.53% 24.57%, rgba(9, 201, 131, 0.849) 0%, rgba(9, 201, 131, 0.788) 100%)
        !important
    );
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .content-buy-btn {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__signin {
      @include button-style(1rem, 20rem, 16rem, none);
    }

    &__mobile {
      @include button-style(
        1rem,
        20rem,
        16rem,
        radial-gradient(75.07% 14.17% at 82.53% 24.57%, #4a92c2 0%, #4a9ac2 100%)
      );
    }

    &__mobile_echec {
      @include button-style(
        1rem,
        20rem,
        16rem,
        radial-gradient(75.07% 14.17% at 82.53% 24.57%, #abababb9 0%, #b8b5b5 100%) !important
      );
    }

    &__mobile_success {
      @include button-style(
        1rem,
        20rem,
        16rem,
        radial-gradient(75.07% 14.17% at 82.53% 24.57%, rgba(9, 201, 131, 0.849) 0%, rgba(9, 201, 131, 0.788) 100%)
          !important
      );
    }
  }
}

.small-text {
  font-size: small;
}
