@font-face {
  font-family: 'Helvetica Neue';
  src: url('/src/styles/fonts/HelveticaNeue.eot');
  src: local('HelveticaNeue'), url('/src/styles/fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
    url('~/src/styles/fonts/HelveticaNeue.woff2') format('woff2'),
    url('~/src/styles/fonts/HelveticaNeue.woff') format('woff'),
    url('~/src/styles/fonts/HelveticaNeue.ttf') format('truetype')
;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/src/styles/fonts/HelveticaNeue-Bold.eot');
  src: local('HelveticaNeue-Bold'), url('/src/styles/fonts/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
    url('~/src/styles/fonts/HelveticaNeue-Bold.woff2') format('woff2'),
    url('~/src/styles/fonts/HelveticaNeue-Bold.woff') format('woff'),
    url('~/src/styles/fonts/HelveticaNeue-Bold.ttf') format('truetype')
    ;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/src/styles/fonts/HelveticaNeue-Black.eot');
  src: local('HelveticaNeue-Black'), url('/src/styles/fonts/HelveticaNeue-Black.eot?#iefix') format('embedded-opentype'),
    url('/src/styles/fonts/HelveticaNeue-Black.woff2') format('woff2'),
    url('/src/styles/fonts/HelveticaNeue-Black.woff') format('woff'),
    url('/src/styles/fonts/HelveticaNeue-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/src/styles/fonts/HelveticaNeue-Medium.eot');
  src: local('HelveticaNeue-Medium'),
    url('/src/styles/fonts/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
    url('~/src/styles/fonts/HelveticaNeue-Medium.woff2') format('woff2'),
    url('~/src/styles/fonts/HelveticaNeue-Medium.woff') format('woff'),
    url('~/src/styles/fonts/HelveticaNeue-Medium.ttf') format('truetype')
;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/src/styles/fonts/HelveticaNeue-Light.eot');
  src: local('HelveticaNeue-Light'), url('/src/styles/fonts/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
    url('/src/styles/fonts/HelveticaNeue-Light.woff2') format('woff2'),
    url('/src/styles/fonts/HelveticaNeue-Light.woff') format('woff'),
    url('/src/styles/fonts/HelveticaNeue-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/src/styles/fonts/HelveticaNeue-UltraLight.eot');
  src: local('HelveticaNeue-UltraLight'),
    url('/src/styles/fonts/HelveticaNeue-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('/src/styles/fonts/HelveticaNeue-UltraLight.woff2') format('woff2'),
    url('/src/styles/fonts/HelveticaNeue-UltraLight.woff') format('woff'),
    url('/src/styles/fonts/HelveticaNeue-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/src/styles/fonts/HelveticaNeue-Thin.eot');
  src: local('HelveticaNeue-Thin'), url('/src/styles/fonts/HelveticaNeue-Thin.eot?#iefix') format('embedded-opentype'),
    url('/src/styles/fonts/HelveticaNeue-Thin.woff2') format('woff2'),
    url('/src/styles/fonts/HelveticaNeue-Thin.woff') format('woff'),
    url('/src/styles/fonts/HelveticaNeue-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
